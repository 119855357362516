<template>
  <v-app>
    <div class="row">

      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div class="d-flex justify-content-center align-items-center image-container">
              <div style='height: 350px; width: 350px; position: relative'>
                <!-- upload Preview -->
                <img
                  class="image"
                  :src="[uploadedImageUrl == '' ? form.photo_name : uploadedImageUrl]"
                  @load="onImageLoad()"
                  v-if="uploadMethod == 'upload'"
                >

                <!-- camera -->
                <div
                  v-if="uploadMethod == 'camera'"
                  class="mb-3"
                >
                  <vue-web-cam
                    ref="webcam"
                    :device-id="deviceId"
                    width="100%"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                    v-if="captureStatus == false"
                  />

                  <figure
                    class="figure"
                    v-else-if="captureStatus == true"
                  >
                    <img
                      :src="baseImg"
                      class="img-responsive"
                      style="height: 350px; width: 350px;"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div class="row">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  v-if="uploadMethod == 'camera'  && captureStatus == false"
                  @click="onCapture"
                ><i class="fas fa-camera"></i></button>
                <button
                  type="button"
                  class="btn btn-secondary w-100"
                  v-if="uploadMethod == 'upload'"
                ><i class="fas fa-camera"></i></button>
              </div>
              <div class="col-md-6">
                <b-button
                  text="Button"
                  variant="success"
                  @click="changeUploadBtn"
                  v-if="uploadMethod == 'camera'"
                  class="w-100"
                >Kamera On</b-button>
                <b-button
                  text="Button"
                  variant="danger"
                  @click="changeUploadBtn"
                  v-if="uploadMethod == 'upload'"
                  class="w-100"
                >Kamera Off</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <!-- Input Name -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Lengkap"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Input Poliklinik -->
          <b-form-group
            id="input-group-polyclinic"
            label="Poliklinik:"
            label-for="input-polyclinic"
          >
            <b-form-select
              id="input-polyclinic"
              v-model="form.polyclinic_id"
              :options="polyclinics"
            ></b-form-select>
            <small class="text-danger">{{ error.polyclinic_id }}</small>
          </b-form-group>

          <!-- Input Sip -->
          <b-form-group
            id="input-group-sip"
            label="Nomor SIP:"
            label-for="input-sip"
          >
            <b-form-input
              id="input-sip"
              v-model="form.sip_number"
              placeholder="Nomor SIP"
            >
            </b-form-input>
            <small class="text-danger">{{ error.sip_number }}</small>
          </b-form-group>

          <!-- Input Sip Expired -->
          <b-form-group
            id="input-group-date"
            label="Masa Berlaku SIP:"
            label-for="input-date"
          >
            <v-dialog
              ref="dialog"
              v-model="datePickerPopUp"
              :return-value.sync="form.sip_exp"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-trigger-datepicker"
                  v-model="form.sip_exp"
                >
                </b-form-input>
              </template>
              <v-date-picker
                v-if="datePickerPopUp"
                v-model="form.sip_exp"
                locale="id"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="datePickerPopUp = false"
                >Cancel</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.sip_exp)"
                >OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <small class="text-danger">{{ error.sip_exp }}</small>
          </b-form-group>

          <!-- Input Id Card -->
          <b-form-group
            id="input-group-id-card-number"
            label="Nomor ID Kartu:"
            label-for="input-id-card-number"
          >
            <b-form-input
              id="input-id-card-number"
              v-model="form.id_card_number"
              placeholder="ID Kartu"
            ></b-form-input>
            <small class="text-danger">{{ error.id_card_number }}</small>
          </b-form-group>

          <!-- Input Email -->
          <b-form-group id="input-group-email">
            <label for="input-email">Email:</label>
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Email"
            ></b-form-input>
            <small class="text-danger">{{ error.email }}</small>
          </b-form-group>

          <template v-if="password">
            <!-- Input Password -->
            <div class="form-group">
              <label for="password">Password:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="passwordIsVisible == 'password' ? passwordIsVisible = 'text' : passwordIsVisible = 'password' "
                  >
                    <div v-if="passwordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="passwordIsVisible"
                  class="form-control"
                  placeholder="Password"
                  v-model="form.password"
                  id="input-password"
                >
              </div>
              <small class="text-danger">{{ error.password }}</small>
            </div>

            <!-- Input Password Confirmation -->
            <div class="form-group">
              <label for="password">Ulangi Password:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="confirmPasswordIsVisible == 'password' ? confirmPasswordIsVisible = 'text' : confirmPasswordIsVisible = 'password' "
                  >
                    <div v-if="confirmPasswordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="confirmPasswordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="confirmPasswordIsVisible"
                  class="form-control"
                  placeholder="Ulangi Password"
                  v-model="form.password_confirmation"
                  id="input-password-confirmation"
                >
              </div>
              <small class="text-danger">{{ error.password_confirmation }}</small>
            </div>
          </template>

          <!-- Input Phone -->
          <b-form-group
            id="input-group-mobile-phone"
            label="Nomor Handphone:"
            label-for="input-mobile-phone"
          >
            <b-form-input
              id="input-mobile-phone"
              v-model="form.mobile_phone"
              placeholder="Nomor Handphone"
            ></b-form-input>
            <small class="text-danger">{{ error.mobile_phone }}</small>
          </b-form-group>

          <!-- Input Telephone -->
          <b-form-group id="input-group-phone">
            <label for="input-phone">Telepon: <em class="text-muted">opsional</em></label>
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              placeholder="Telepon"
            ></b-form-input>
            <small class="text-danger">{{ error.phone }}</small>
          </b-form-group>

          <!-- Input Address -->
          <b-form-group id="input-group-address">
            <label for="input-address">Alamat: <em class="text-muted">opsional</em></label>
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              placeholder="Alamat Lengkap"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group>

          <!-- Profile Image -->
          <b-form-group id="input-group-image">
            <label for="input-image">Foto Profil: <em class="text-muted">opsional</em></label>
            <b-form-file
              v-model="form.photo"
              placeholder="Upload Gambar"
              drop-placeholder="Drop file here..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.photo }}</small>
          </b-form-group>

          <!-- Button -->
          <b-button
            type="submit"
            variant="primary"
          >Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="$router.push('/doctor/list')"
          >
            Batal
          </b-button>
          <b-button
            class="ml-2"
            variant="danger"
            type="reset"
          >
            Reset
          </b-button>
        </b-form>
      </div>

    </div>
  </v-app>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { WebCam } from 'vue-web-cam'

export default {

  props: {
    form: Object,
    route: String,
    password: Boolean
  },

  components: {
    "vue-web-cam": WebCam
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        sip_number: "",
        sip_exp: "",
        id_card_number: "",
        mobile_phone: "",
        phone: "",
        address: "",
        polyclinic_id: "",
        photo: ""
      },
      datePickerPopUp: false,
      // Other
      polyclinics: [],
      imgLoaded: false,
      uploadedImageUrl: '',
      uploadMethod: 'upload',
      passwordIsVisible: 'password',
      confirmPasswordIsVisible: 'password',
      // Camera
      baseImg: null,
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      captureStatus: false,
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0])
      this.form.photo = evt.target.files[0]
    },

    oncancel() {
      if (this.purpose == 'master') {
        this.onStop()
        $router.push('/patient/list')
      } else if (this.purpose == 'modal') {
        this.onStop()
        $bvModal.hide('modal-patient')
      }
    },

    changeUploadBtn() {
      if (this.uploadMethod == 'upload') {
        this.uploadMethod = 'camera'
        setTimeout(() => {
          this.onStart()
        }, 4000)
      } else {
        this.uploadMethod = 'upload'
      }
    },

    // camera method
    onCapture() {
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg)
      this.captureStatus = true
      this.onStop()

    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onPause() {
      this.$refs.webcam.pause();
    },
    onResume() {
      this.$refs.webcam.resume();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString
      });
    },

    async getPolyclinics() {
      let response = await module.setSelectOption('polyclinics')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.polyclinics = response.data
        this.polyclinics.push({ text: "Pilih Poliklinik", value: null, disabled: true })
      }
    },

    async formOnSubmit() {
      let formData = new FormData()
      if (this.img != null) {
        this.form.photo = this.img
      }
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value)
      }
      // Make Request
      let response = await module.submit(formData, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/doctor/list')
      }
    }

  },

  computed: {
    device: function () {
      return this.devices.find(n => n.deviceId === this.deviceId)
    }
  },

  watch: {
    camera: function (id) {
      this.deviceId = id
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    }
  },

  mounted() {
    // Get Polyclinics
    this.getPolyclinics()
  }

}
</script>